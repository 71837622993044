import styled from "styled-components"
import React from "react"
import { color, typography } from "../shared/styles"

const BenefitTitle = styled.div`
  margin-top: 30px;
  font-size: ${typography.size.h3}px;
  font-weight: ${typography.weight.semibold};
`;

const BenefitParagraph = styled.div`
  font-size: ${typography.size.p2}px;
  color: ${color.textSecondary};
  text-align: center;
  margin: 12px 32px 0;
`;

const Circle = styled.div`
  width: 108px;
  height: 108px;
  
  border-radius: 54px;
  
  font-size: 32px;
  font-weight: ${typography.weight.medium};
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Benefit({ children, color, backgroundColor, title, description }) {
  return (
    <Wrapper>
      <Circle color={color} backgroundColor={backgroundColor}>{children}</Circle>
      <BenefitTitle>{title}</BenefitTitle>
      <BenefitParagraph>{description}</BenefitParagraph>
    </Wrapper>
  )
}