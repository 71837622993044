import React from "react"
import Footer from "../../layout/Footer"
import { background } from "../../shared/styles"
import Section from "../../layout/Section"
import SectionHeader from "../../layout/SectionHeader"
import ColumnLayout from "../../layout/ColumnLayout"
import Benefit from "../../layout/Benefit"
import HeartIconSVG from "../../../assets/heart2-icon.svg"
import NeverSettleSVG from "../../../assets/never-settle.svg"
import CheckboxIconSVG from "../../../assets/checkbox-icon.svg"

export default function CareersScreen() {
  return (
    <>
      <Section backgroundColor={background.bg}>
        <SectionHeader>Join us in changing the way people pay</SectionHeader>
      </Section>
      <Section>
        <ColumnLayout columns="1,1,1">
          <Benefit
            color="#EE3838"
            backgroundColor="#FEF2F2"
            title="Love What You Do"
            description="Feel rewarded by making a positive impact on the world.">
            <HeartIconSVG />
          </Benefit>

          <Benefit
            color="#0EA480"
            backgroundColor="#E9F5F4"
            title="Never Settle"
            description="Just because that’s how things are done is not good enough.">
            <NeverSettleSVG />
          </Benefit>

          <Benefit
            color="#8370F4"
            backgroundColor="#F1F1FC"
            title="Get Shit Done"
            description="We work hard and smart with great attention to detail.">
            <CheckboxIconSVG />
          </Benefit>
        </ColumnLayout>
      </Section>
      <Footer />
    </>
  )
}
